// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "jquery.dirtyforms"
import "bootstrap/js/dist/collapse"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/tooltip"
import LocalTime from "local-time"
import Masonry from "masonry-layout"

Rails.start()
ActiveStorage.start()
LocalTime.start()

window.$ = window.jQuery = require('jquery')
const imagesLoaded = require('imagesloaded')

$(() => {
  $('form').dirtyForms()
  $('[data-bs-toggle="tooltip"]').tooltip()

  $('[data-masonry]').imagesLoaded((il) => {
    if (il.elements.length > 0) {
      new Masonry('[data-masonry]').layout();
    }
  })
})